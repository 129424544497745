*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

body {
  position: relative;
  margin: 0;
  --color-text: #C7FEC7;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #fff;
  --font-size-s: 5vw;
  --font-size-m: 9vw;
  --font-size-l: 24vw;
  --font-size-xl: 26vw;
  /*  --font-family: widescreen-ex, sans-serif;*/
  --font-weight: 900;
  --content-offset: 20vh;
  --font-case: uppercase;
  --color-rep-text: var(--color-text);
  --color-rep-text-main: var(--color-text);
  --color-rep-outline: var(--color-text);
  /* Adjust these depending on your font */
  --font-factor: 0.059;
  --font-line: 0.745;
  color: var(--color-text);
  background-color: #000;
  font-family: halyard-micro, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  overflow: hidden;*/
  /*  overflow-y: scroll;*/
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  /* Provide a fallback style for browsers
   that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
   that do support :focus-visible */
  background: transparent;
}

a:focus-visible {
  /* Draw a very noticeable focus style for
   keyboard-focus on browsers that do support
   :focus-visible */
  outline: 2px solid red;
  background: transparent;
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
}

.unbutton:focus {
  outline: none;
}

main {
  padding: 1.5rem 2rem 0;
}



.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*  width: 70vw;*/
  /*  margin: 0 15vw;*/
  width: 100%;
}

.content p,
.artists {
  hyphens: auto;
  font-size: 1.25rem;
  line-height: 1.8;
  margin: 0 0 5vw;
  text-align: center;
  width: 100%;
  max-width: 900px;
}

.artists {
  margin-bottom: 40px;
}

@media (max-width: 650px) {

  .content p,
  .artists {
    font-size: 1em;
  }
}

p.footer {
  padding-top: 10vw;
  font-size: 1rem;

  max-width: 750px;
}

.content__title--size-s {
  --size: var(--font-size-s);
}

.content__title--size-m {
  --size: var(--font-size-m);
}

.content__title--size-l {
  --size: var(--font-size-l);
}

.content__title--size-xl {
  --size: var(--font-size-xl);
}

.content__title {
  font-size: var(--size);
  margin: 0;
  display: grid;
  /*font-family: var(--font-family);

  font-weight: var(--font-weight);
  text-transform: var(--font-case);*/
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  mix-blend-mode: lighten;
  text-align: center;
  /*  font-family: aktiv-grotesk-extended, "Helvetica Neue", "Arial", sans-serif;*/

}

.content__title--left {
  margin-right: auto;
}

.content__title--right {
  margin-left: auto;
}

.text-rep span {
  color: var(--color-rep-text-main);
  grid-area: 1 / 1 / 2 / 2;
  background: #000;
  line-height: var(--font-line);
  padding-bottom: calc(var(--font-factor)* var(--size));
  will-change: transform;
}

.text-rep span:not(:last-child) {
  color: var(--color-rep-text);
}

@media screen and (min-width: 53em) {
  .frame {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-areas: 'title demos demos sponsor' 'links links links links';
    grid-column-gap: 3vw;
    grid-row-gap: 1rem;
    justify-content: space-between;
    text-align: left;
  }

  .frame__title {
    grid-area: title;
  }

  .frame__links {
    margin: 0;
    grid-area: links;
  }

  .frame__links--demos {
    grid-area: demos;
  }
}

.background {
  position: fixed;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background video {
  display: block;
  /*  transform: rotate(5deg);*/
  max-width: 100vw;
  max-height: 100vh;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  background-image: radial-gradient(black 2px, transparent 0);
  background-size: 4px 4px;
  background-position: -19px -19px;
}

.logo {
  width: 100%;
  mix-blend-mode: lighten;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: widescreen-ex, sans-serif;
  /*  font-family: aktiv-grotesk-extended, "Helvetica Neue", "Arial", sans-serif;*/
  text-transform: uppercase;
  /*  text-shadow: 0 0 30px #e2ff3d;*/
  /*  letter-spacing: 0.25em;*/
  margin: 0;
  font-size: 1.8vw;
}

h2 {
  margin-bottom: 1.5vw;
}

@media (max-width: 850px) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 3vw;
  }
}

@media (max-width: 650px) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 4vw;
  }
}

.masthead {
  text-align: center;
  margin: 5vh 0 5vh;
}

.player {
  /*  background-color: rgba(0, 0, 0, 0.5);*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vw;
}

.player iframe {
  box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.2);
  border: 1px solid;
  background-color: #000;
  width: 100%;
  height: 50vw;
  max-height: 90vh;
}

@media (max-width: 1000px) {
  .embed-placeholder {
    width: 100%;
    height: 45vw;
  }
}

@media (max-width: 650px) {
  .embed-placeholder {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

.embed-placeholder img {
  width: 30px;
  margin-top: -15px;
}

.schedule-table {
  width: 100%;
  max-width: 800px;
  margin-bottom: 5vw;
}

.schedule-line {
  display: flex;
  /*  justify-content: space-between;*/
  border-bottom: 1px dotted #C7FEC7;
  justify-content: center;
  /*  align-items: center;*/
  padding: 20px 0;
  gap: 40px;
  font-size: 16px;
}

@media (max-width: 800px) {
  .schedule-line {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    /*    justify-content: center;*/

  }
}

.schedule-date {
  text-transform: uppercase;
  font-family: widescreen-ex, sans-serif;

  /*  margin-right: auto;*/
}

.schedule-action {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  width: 300px;
  position: relative;
  align-items: flex-start;
}

.schedule-action span {
  cursor: pointer;
}

@media (max-width: 800px) {
  .schedule-action {
    width: auto;
    align-items: center;
  }
}

.schedule-item {
  display: flex;
  flex-direction: column;
  line-height: 1.2em;

}

.schedule-item span:last-child {
  color: #fff;
  opacity: 0.8;
}

.tours {
  display: flex;
  /*  flex-direction: column;*/
  flex-wrap: wrap;
  gap: 60px 40px;
  margin: 0 auto 5vw;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
}

.tour {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  width: calc(33% - 40px);
}

.video-placeholder {
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 180px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: widescreen-ex, sans-serif;
  text-transform: uppercase;
  gap: 5px;
}

.video-placeholder img {
  width: 20px;
}

@media (max-width: 1200px) {
  .tour {
    width: calc(50% - 40px);
  }

  .video-placeholder {
    height: 20vw;
  }
}

@media (max-width: 1000px) {
  .tour {
    width: 100%;
  }

  .video-placeholder {
    height: 40vw;
  }
}


.tour-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.tour .button {
  border: 1px solid;
  padding: 20px 10px;
  border-radius: 3px;
  width: 100%;
  border-color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
}

.nac-logo {
  width: 110px;
  display: block;
  margin: 0 auto 40px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #888;
  font-size: 12px;
}

.nac-logo img {
  width: 100%;
}

.tippy-content {
  padding: 20px !important;
  text-align: center;
}

.tippy-content a {
  font-size: 16px;
  color: 92aaff;
  font-weight: bold;
  text-decoration: underline;
}

.tippy-content a:hover {
  color: #fff;
}